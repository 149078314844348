import React from 'react';
import Layout from '../components/layout';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import GitLogo from '../images/github-32px.png';
import LinkedInLogo from '../images/linkedin-32px.png';




function Update(){
    // console.log(Image);
    // console.log(typeof Image);
    return(
        <Layout>
            <p className="blog-content" style={{
                fontSize:18,
                fontWeight:800,
            }}>
                MEET my weird cats: Toasty and Mittens
            </p>
            <div style={{
                padding: `0 20% 0 20%`
            }}>
                <CatImage />
            </div>
            
            <br />
            <br />

            <p style={{
                fontSize:18,
                fontWeight:800
            }}>
                I'd always love a good conversation. You can also find me here:
            </p>
            <div style={{
                display:`flex`,
                flexDirection:`row`,
                justifyContent:`space-around`,
                margin:`0 35% 0 35%`
            }}>
                <a href="https://github.com/erinhyperplane" target="_blank">
                    <img src={GitLogo} alt="contact erin xue on github"  />
                </a>
                <a href="https://www.linkedin.com/in/erinx/" target="_blank" >
                    <img src={LinkedInLogo} alt="contact erin xue on linkedin" />
                </a>
            </div>
            
        </Layout>

    )
    
}

export default Update;

const CatImage = () => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "cats.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 650) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
  
    return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
  }

